<template>
  <div>
    <class-list-add-new
      v-if="$can('roster.class.create')"
      :create-class-active.sync="createClassActive"
      :section-id="sectionId"
      :key="editClassData.id"
      @refetch-data="classRefresh"
    />

    <class-list-edit
      v-if="$can('roster.class.update')"
      :edit-class-active.sync="editClassActive"
      :edit-class-data.sync="editClassData"
      :key="editClassData.id"
      @refetch-data="classRefresh"
    />

    <div class="d-flex align-items-center mb-75">
      <h3 class="mb-0 mr-50">Classes</h3>
      <div
        v-if="$can('roster.class.create')"
        @click="createClassActive = true"
        class="d-flex align-items-center text-muted cursor-pointer"
        style="margin-top: 3px; margin-left: 3px"
      >
        <feather-icon icon="PlusCircleIcon" size="18" class="mr-25" />
        <span>New Class</span>
      </div>
    </div>

    <!-- <div class="mb-75">
      <b-row>
        <b-col cols="12" sm="8" md="9">
          <div class="d-flex align-items-center justify-content-start flex-wrap flex-sm-nowrap" style="gap: 0.675rem">
            <b-form-input v-model="searchQuery" class="drop-shadow-default" placeholder="Search..." style="max-width: 28rem" />
          </div>
        </b-col>

        <b-col cols="12" sm="4" md="3" class="d-flex align-items-center justify-content-end">
          <b-button variant="primary" @click="createClassActive = true">
            <span class="text-nowrap">Add Class</span>
          </b-button>
        </b-col>
      </b-row>
    </div> -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0 table-no-top table-text-nowrap">
      <b-table
        ref="refClassListTable"
        class="position-relative"
        :items="fetchClasses"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No classes assigned to this course"
        :sort-desc.sync="isSortDirDesc"
        @row-contextmenu="onRowContextMenu"
      >
        <!-- Column: Class -->
        <template #cell(name)="data">
          <b-link :to="{ name: 'apps-roster-class-view', params: { id: data.item.id } }" class="font-weight-bold d-block text-nowrap">
            {{ data.item.name }}
          </b-link>
          <small class="text-muted">{{ (data.item.classes || []).length }} total classes</small>
        </template>

        <!-- Column: Date of Class -->
        <template #cell(date)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              <!-- {{ momentTz(data.item.startTime).format('dddd, MMMM Do, YYYY') }} -->
              <formatted-time :timestamp="data.item.startTime" format="dddd, MMMM Do, YYYY" />
            </span>
          </div>
        </template>

        <!-- Column: Live Classes -->
        <template #cell(time)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              <formatted-time :timestamp="data.item.startTime" format="hh:mm A zS" />
            </span>
            -
            <span class="align-text-top text-capitalize">
              <formatted-time :timestamp="data.item.endTime" format="hh:mm A zS" />
            </span>
          </div>
        </template>

        <!-- Column: Live Classes -->
        <template #cell(live_classes)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.classes.filter((clazz) => clazz.status === 'live').length }}</span>
          </div>
        </template>

        <!-- Column: Visibility -->
        <template #cell(type)="data">
          <b-badge :variant="`light-${data.item.type === 'class' ? 'success' : 'warning'}`" class="text-capitalize">
            {{ data.item.type }}
          </b-badge>
        </template>

        <!-- Column: Visibility -->
        <template #cell(location)="data">
          <b-badge :variant="`light-${data.item.location === 'virtual' ? 'info' : 'secondary'}`" class="text-capitalize">
            {{ data.item.location }}
          </b-badge>
        </template>

        <!-- Column: Visibility -->
        <template #cell(visibility)="data">
          <b-badge :variant="`light-${resolveClassVisibilityVariant(data.item.visibility)}`" class="text-capitalize">
            {{ data.item.visibility.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge :variant="`${resolveClassStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="d-flex align-items-center pl-25">
            <feather-icon
              v-if="$can('roster.class.update')"
              icon="EditIcon"
              size="16"
              class="cursor-pointer mr-50"
              @click="doClassEdit(data.item)"
            />

            <feather-icon
              v-if="$can('roster.class.delete')"
              icon="DeleteIcon"
              size="16"
              class="cursor-pointer"
              @click="doClassDelete(data.item)"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-1">
        <b-row>
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="mb-0 text-muted">{{ dataMeta.of }} Total Entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" md="9" class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
            <div class="mr-2 vue-select-up">
              <span class="mb-0 mr-75">Rows Per Page</span>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block"
                :open="true"
              />
            </div>
            <span class="mr-2 ml-lg-50 mb-0">Page {{ currentPage }} of {{ totalPageCount }}</span>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalClasses"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-sm-0 ml-lg-50"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <vue-context ref="classListMenu" class="mw-8" v-slot="{ data }">
      <li v-if="data">
        <a v-if="$can('roster.class.update')" class="d-flex align-items-center justify-content-between" @click="doClassEdit(data.item)">
          <span>Edit</span>
          <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
        </a>
        <a v-if="$can('roster.class.delete')" class="d-flex align-items-center justify-content-between" @click="doClassDelete(data.item)">
          <span>Delete</span>
          <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
        </a>
      </li>
    </vue-context>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import ClassListFilters from './ClassListFilters.vue';
import useClassesList from './useClassesList';
import rosterStoreModule from '../../../../../store/roster/rosterStoreModule';
import ClassListAddNew from './ClassListAddNew.vue';
import VueContext from 'vue-context';
import ClassListEdit from './ClassListEdit.vue';
import FormattedTime from '@/@core/components/time/FormattedTime.vue';

export default {
  components: {
    ClassListFilters,
    ClassListAddNew,
    ClassListEdit,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    FormattedTime,

    vSelect,
    VueContext,
  },
  props: {
    sectionId: {
      type: String,
      required: false,
    },
  },
  methods: {
    classRefresh() {
      this.refetchData();
      this.$emit('refetch-data');
    },
    onRowContextMenu(item, index, event) {
      this.$refs.classListMenu.open(event, { item });
      event.preventDefault();
    },
    doClassEdit(existingClassData) {
      console.log(`starting edit for`, existingClassData);
      this.editClassData = existingClassData;
      this.editClassActive = true;
    },
    doClassDelete(classData) {
      if (!classData || !classData.id) {
        // TODO: add alert that cannot delete
        return;
      }
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to delete this class?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          store
            .dispatch('rosterStoreModule/deleteClass', { id: classData.id })
            .then(() => {
              // TODO: Add alert that class was deleted and removed from course (if applicable)
              this.classRefresh();
            })
            .catch((error) => {
              // TODO: Add error dialog
            });
        }
      });
    },
  },
  setup(props) {
    const createClassActive = ref(false);
    const editClassActive = ref(false);
    const editClassData = ref({});

    const {
      fetchClasses,
      tableColumns,
      perPage,
      currentPage,
      totalPageCount,
      totalClasses,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClassListTable,
      refetchData,

      statusOptions,
      visibilityOptions,

      refSectionId,

      // UI
      resolveUserRoleVariant,
      resolveClassVisibilityVariant,
      resolveClassStatusVariant,

      // Extra Filters
      visibilityFilter,
      statusFilter,
    } = useClassesList();

    if (props.sectionId) {
      refSectionId.value = props.sectionId;
    }

    return {
      // Sidebar
      createClassActive,
      editClassActive,
      editClassData,

      fetchClasses,
      tableColumns,
      perPage,
      currentPage,
      totalPageCount,
      totalClasses,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClassListTable,
      refetchData,
      refSectionId,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveClassVisibilityVariant,
      resolveClassStatusVariant,

      visibilityOptions,
      statusOptions,

      // Extra Filters
      visibilityFilter,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
